<template>
  <b-container>
    <b-row>
      <b-col>
        <div class="mt-1">
          <h4>{{ description }}</h4>
          <span v-if="groupList">Für {{ pcCount }} Computer</span>
          <span v-else>{{ pcHostname }}</span>
        </div>
      </b-col>
      <b-col v-if="type === 'createTaskModal'">
        <b-button-group size="lg">
          <b-button :class="[state.includes('start') ? 'btn-light' : '', 'disabled-header']" @click="changeState('start')">Start</b-button>
          <b-button :class="[state.includes('select') ? 'btn-light' : '', 'disabled-header']" disabled>Auswahl</b-button>
          <b-button :class="[state.includes('option') ? 'btn-light' : '', 'disabled-header']" disabled>Optionen</b-button>
          <b-button :class="[state.includes('finish') ? 'btn-light' : '', 'disabled-header']" @click="changeState('finish')">Übersicht</b-button>
        </b-button-group>
      </b-col>
      <b-col>
        <button type="button" aria-label="Close" class="close" @click="close()">×</button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'TaskModalHeader',
  props: ['description', 'pcHostname', 'close', 'groupList', 'pcCount', 'type', 'state'],
  methods: {
    changeState(data) {
      this.$emit('change-state', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/styles/Modal";
</style>
