<template>
  <b-modal :id="'deliveryNoteModal-' + pcUuid" size="lg">
    <template #modal-header="{ close }">
      <TaskModalHeader :close="close" description="PC - Lieferschein" :pcHostname="pcHostname"/>
    </template>
    <template #default>
      <div v-if="pcCustomerAssigned">
        <div>
          <span class="text-bold">Aktuelle Kundenzuweisung:</span><br>
          {{ pcCustomerAssigned.zuname }}, {{ pcCustomerAssigned.vorname }} ({{ pcCustomerAssigned.kdnr }})<br>
          {{ pcCustomerAssigned.kurs }}<br>
          Klassenraum-{{ pcCustomerAssigned.intklr }}
        </div>
        <div class="mt-3">
          <span class="text-bold">Daten für Lieferschein:</span>
          <b-form-row>
            <b-col cols="6">
              <label class="mb-0 mt-1">Laptoptasche:</label>
              <b-form-checkbox class="ml-1" v-model="laptopBag" trim/>
            </b-col>
            <b-col cols="6">
              <label class="mb-0 mt-1">Monitortasche:</label>
              <b-form-checkbox class="ml-1" v-model="monitorBag" trim/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <label class="mb-0">Anzahl Tastaturen:</label>
              <b-form-input v-model="keyboardCount" placeholder="Anzahl Tastatur" type="number" min="0" trim/>
            </b-col>
            <b-col cols="6">
              <label class="mb-0">Anzahl Mäuse:</label>
              <b-form-input v-model="mouseCount" placeholder="Anzahl Maus" type="number" min="0" trim/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <label class="mb-0 mt-1">Anzahl Headsets:</label>
              <b-form-input v-model="headsetCount" type="number" min="0" trim/>
            </b-col>
            <b-col cols="6">
              <label class="mb-0 mt-1">Anzahl Netzwerkkabel:</label>
              <b-form-input v-model="networkCableCount" type="number" min="0" trim/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <label class="mb-0 mt-1">Anzahl HDMI/DVI/VGA Kabel:</label>
              <b-form-input v-model="cableCount" type="number" min="0" trim/>
            </b-col>
            <b-col cols="6">
              <label class="mb-0 mt-1">Anzahl Monitore:</label>
              <b-form-input v-model="monitorCount" type="number" min="0" trim/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <label class="mb-0 mt-1">Anzahl externe Kameras:</label>
              <b-form-input v-model="externalCameraCount" type="number" min="0" trim/>
            </b-col>
            <b-col cols="6">
              <label class="mb-0 mt-1">Anzahl Laptoperhöhungen:</label>
              <b-form-input v-model="pcElevationCount" type="number" min="0" trim/>
            </b-col>
          </b-form-row>
        </div>
      </div>
      <div v-else>
        Aktuelle Kundenzuweisung:<br>
        -<br>
        <br>
        Zum Markieren muss eine Kundenzuweisung vorliegen.
      </div>
    </template>
    <template #modal-footer>
      <b-button variant="primary" class="mx-auto" @click="download" :disabled="disabledButton">Lieferschein herunterladen</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import TaskModalHeader from "@/components/lists/computerList/tasks/utility/TaskModalHeader.vue";

export default {
  name: 'DeliveryNoteModal',
  props: ['pcUuid', 'pcId', 'pcCustomerAssigned', 'pcHostname'],
  components: {
    TaskModalHeader
  },
  data() {
    return {
      keyboardCount: '0',
      mouseCount: '0',
      headsetCount: '0',
      networkCableCount: '0',
      cableCount: '0',
      monitorCount: '0',
      externalCameraCount: '0',
      pcElevationCount: '0',
      laptopBag: false,
      monitorBag: false
    }
  },
  methods: {
    async download() {
      let config = {
        method: 'post',
        url: 'downloads/delivery-note-site',
        data: {
          pc_id: this.pcId,
          keyboard_count: this.keyboardCount,
          mouse_count: this.mouseCount,
          headset_count: this.headsetCount,
          network_cable_count: this.networkCableCount,
          cable_count: this.cableCount,
          monitor_count: this.monitorCount,
          external_camera_count: this.externalCameraCount,
          pc_elevation_count: this.pcElevationCount,
          laptop_bag: this.laptopBag,
          monitor_bag: this.monitorBag
        },
        responseType: 'blob'
      }
      await axios(config)
          .then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"]})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["content-disposition"].split('=')[1]
            link.click()
            URL.revokeObjectURL(link.href)
          })
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    disabledButton() {
      return this.pcCustomerAssigned === null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
