<template>
  <b-badge variant="primary" class="p-1 px-2 b-l clickable" :title="title" v-clipboard:copy="value"
           v-clipboard:success="copySuccess" v-clipboard:error="copyError">
    <b-icon-clipboard scale="1.3"/>
  </b-badge>
</template>

<script>
import {toastDanger, toastInfo} from "@/modules/status";

export default {
  name: "ClipboardUtility",
  props: ['value', 'title'],
  methods: {
    copySuccess() {
      toastInfo(this, 'Kopieren erfolgreich!')
    },
    copyError() {
      toastDanger(this, 'Kopieren fehlgeschlagen!')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
