<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Hostname..."/>
              </b-col>
              <b-col cols="2">
                <b-form-select v-model="selectedTeam" :options="teamList" size="sm"/>
              </b-col>
              <b-col cols="auto" class="ml-auto">
                <b-button size="sm" variant="primary" class="mb-2" @click="refresh">
                  <b-icon-search/>
                </b-button>
              </b-col>
            </b-row>
          </SubHeader>
          <div v-if="groupsFiltered">
            <div v-for="group in groupsFiltered" :key="group.id">
              <Group :group="group" class="mt-3">
                <template #group-content>
                  <div v-for="computer in computers(group)" :key="computer.id">
                    <ShippingRow :computer="computer" @refresh="refresh"/>
                  </div>
                </template>
              </Group>
            </div>
          </div>
          <ScrollToTop/>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import {postRequest} from "@/modules/requests";
import Group from "@/components/lists/Group";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import ShippingRow from "@/components/lists/shippingList/ShippingRow";

export default {
  name: "ShippingList",
  components: {
    Group,
    SubHeader,
    ShippingRow,
    ScrollToTop
  },
  data() {
    return {
      search: '',
      selectedTeam: null,
      groups: null,
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getShippingList() {
      let data = {
        team: this.selectedTeam
      }
      await postRequest('shippingList', data, this, null)
          .then((response) => {
            this.groups = response.data
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getShippingList()
      this.setLoadingState(false)
    },
    computers(group) {
      return _.filter(group.computers, (computer) => {
        return computer.hostname.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  computed: {
    ...mapGetters(['teams']),
    ...mapGetters(['teamSelected']),
    groupsFiltered() {
      return _.filter(this.groups, (group) => {
        return _.filter(group.computers, (computer) => {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase())
        }).length > 0
      })
    },
    teamList() {
      let teamList = []
      _.forEach(this.teams, (team) => {
        teamList.push({value: team.shortname, text: team.displayname})
      })
      return teamList
    }
  },
  async mounted() {
    this.selectedTeam = this.teamSelected.shortname
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
